import React from "react";
import styled, { ThemeProvider } from "styled-components";

import Button from '../Button';
import {dark, light} from '../../styles/Themes';
import menu from "../../assets/LR/menu.gif";

const Section = styled.section`
min-height: 100vh;
width: 100%;
background-color:black;


display: flex;
justify-content: center;
align-items: center;
position: relative;
`

const Container = styled.div`
width: 75%;
margin: 1rem auto;
//background-color: lightblue;

display: flex;
justify-content: center;
align-items: center;

@media (max-width: 70em) {
    width: 85%
}

@media (max-width: 64em) {
    width: 100%;
    flex-direction: column;

    &>*:last-child{
        width: 80%;
    }
}

@media (max-width: 40em) {
   
    &>*:last-child{
        width: 90%;
    }
}
`

const Box = styled.div`
width: 50%;
height: 100%;
min-height: 60vh;
margin: 1rem;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;

@media (max-width: 40em) {
    min-height: 50vh;
}
`



const Title = styled.h2`
font-size: ${(props) => props.theme.fontxxl};
text-transform: capitalize;
color: ${props => `rgba(${props.theme.textRgba}, 0.9)`};
align-self: flex-start;
width: 80%;
margin: 0 auto;

@media (max-width: 64em) {
    width: 100%;
    text-align: center;
}

@media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontxl};
}

@media (max-width: 30em) {
    font-size: ${(props) => props.theme.fontlg};
}
`

const Subtext = styled.p`
font-size: ${(props) => props.theme.fontlg};
color: ${(props) => props.theme.body};
align-self: flex-start;
width: 80%;
margin: 1rem auto;
font-weight: 400;

@media (max-width: 64em) {
    width: 100%;
    text-align: center;
    font-size: ${(props) => props.theme.fontmd};
}

@media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontmd};
}

@media (max-width: 30em) {
    font-size: ${(props) => props.theme.fontsm};
}

`


const SubtextLight = styled.p`
font-size: ${(props) => props.theme.fontmd};
color: ${props => `rgba(${props.theme.textRgba}, 0.9)`};
align-self: flex-start;
width: 80%;
margin: 1rem auto;
font-weight: 400;

@media (max-width: 64em) {
    width: 100%;
    text-align: center;
    font-size: ${(props) => props.theme.fontsm};
}

@media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontsm};
}

@media (max-width: 30em) {
    font-size: ${(props) => props.theme.fontxs};
}

`



const About = () => {
    return(
        <Section id= "about">
            
            <Container>
                <Box> <img src={menu} alt="Logo" /> </Box>
                <Box>
                    <Title>
                    Enter A <br /> World of <br /> Deep Fantasy <br />
                    </Title>
                    <Subtext>
                    Lost Realms has been crafted with love by a full stack team of pixel artists, storytellers, and game designers. We aim to deliver a vivid pixel experience that captures the essence of our favorite games from childhood, for Web3 native players.

                    Our ecosystem will showcase immersive art including fully generative NFT heroes, unique environments and world maps, alongside a live and constantly expanding lore.
                    </Subtext>
                    <SubtextLight>
                    Be ready to dive deep into a utility-first economy requiring deep decision making, to forge your unique story. Level up your heroes with active gameplay while dynamically updating your metadata in a zero-gas experience.

                    Our aim is to provide you with technically innovative, seamless gameplay, and we have plenty up our sleeves.
                    </SubtextLight>
                    
                </Box>
            </Container>
        </Section>

    
      )
}

export default About