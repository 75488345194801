import GlobalStyles from './styles/GlobalStyles'
import React from 'react';
import { ThemeProvider } from 'styled-components';
import {light, dark} from './styles/Themes';
import 'swiper/swiper-bundle.css';



import Home from './components/sections/Home';
import About from './components/sections/About';
import About2 from './components/sections/About2';
import Faq from './components/sections/Faq';

import Team from './components/sections/Team';
import Footer from './components/Footer';



function App() {
  return (
   <>
   <GlobalStyles />
   <ThemeProvider theme={light}>
    
    <Home />
    <About />
    <Team />
    <About2 />
   
    <Faq />
    <Footer />
    
   </ThemeProvider>
   </>
  );
}

export default App;
