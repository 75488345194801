import React from 'react'
import styled from 'styled-components'
import Typewriter from 'typewriter-effect';
import Button from './Button';
import LostRealms from "../assets/LR/LR black.png";
import CoverVideo from './CoverVideo';


const Title = styled.h2`
font-size: ${(props) => props.theme.fontxxl};
text-transform: capitalize;
width: 80%;
color: ${props => `rgba(${props.theme.textRgba}, 0.8)`};
text-shadow: -1px 1px 10px rgba(0, 0, 0, 0.75);
align-self: flex-start;

span{
    text-transform: uppercase;
    font-family: 'Akaya Telivigala', cursive;
}
.text-1{
    color: blue;
}
.text-2{
    color: green;
}
.text-3{
    color: gray;
}
.text-4{
    color: red;
}

@media (max-width: 70em) {
    /* mobile */
    font-size: ${(props) => props.theme.fontxl};
}

@media (max-width: 48em) {
    align-self: center;
    text-align: center;
}

@media (max-width: 40em) {
    width: 90%;
}


`

const Subtitle = styled.h3`
font-size: ${(props) => props.theme.fontlg};
text-transform: capitalize;
color: ${props => `rgba(${props.theme.textRgba}, 0.8)`};
text-shadow: -1px 1px 10px rgba(0, 0, 0, 0.75);
font-weight:600;
margin-bottom: 1rem;
width: 80%;
align-self: flex-start;

@media (max-width: 40em) {
    /* mobile */
    font-size: ${(props) => props.theme.fontmd};
}

@media (max-width: 48em) {
    align-self: center;
    text-align: center;
}



`

const Box = styled.div`
width: 50%;
height: 100%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
`

const Container = styled.div`
width: 75%;
margin: 2rem auto;

display: flex;
justify-content: space-between;
align-items: center;
flex-wrap: wrap;

@media (max-width: 64em) {
  width: 80%;
}

@media (max-width: 48em) {
  width: 90%;
  justify-content: center;
}
`

//const ButtonContainer = styled.div`
//width: 80%;
//align-self: flex-start;
//
//@media (max-width: 48em) {
//    align-self: center;
//    text-align: center;
//
//    button{
//        margin: 0 auto;
//    }
//}

//`
//<ButtonContainer>
//<Button text="Explore" link="#about"/>
//</ButtonContainer>

const TypeWriterText = () => {
  return (
 <>
   <CoverVideo></CoverVideo>
    
    <Title>
        
        The Home of 
        <Typewriter
        options={{
            autoStart: true,
            loop:true,
        }}
  onInit={(typewriter) => {
    typewriter.typeString('<span class="text-1">Dwarves</span>')
      .pauseFor(2000)
      .deleteAll()
      .typeString('<span class="text-2">Elves</span>')
      .pauseFor(2000)
      .deleteAll()
      .typeString('<span class="text-3">Centaurs</span>')
      .pauseFor(2000)
      .deleteAll()
      .typeString('<span class="text-4">Ninjas</span>')
      .pauseFor(2000)
      .deleteAll()
      .typeString('<span class="text-5">Minotaurs</span>')
      .pauseFor(2000)
      .deleteAll()
      .typeString('<span class="text-6">Anubis</span>')
      .pauseFor(2000)
      .deleteAll()
      .typeString('<span class="text-7">Pirates</span>')
      .pauseFor(2000)
      .deleteAll()
      .typeString('<span class="text-8">Wizards</span>')
      .pauseFor(2000)
      .deleteAll()
      .start()
  }}
/>
    </Title>
    <Subtitle>
       Adventure awaits!

        

    </Subtitle>
    
 </>
  )
}

export default TypeWriterText