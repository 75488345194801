import React from 'react'
import styled from 'styled-components'


import Partners from '../../Icons/Partners'

import img1 from '../../assets/Nfts/member1.png';
import img2 from '../../assets/Nfts/member2.png';
import img3 from '../../assets/Nfts/member3.png';
import img4 from '../../assets/Nfts/member4.png';
import img5 from '../../assets/Nfts/member5.png';
import img6 from '../../assets/Nfts/member6.png';
import img7 from '../../assets/Nfts/member7.png';
import img8 from '../../assets/Nfts/member8.png';
import img9 from '../../assets/Nfts/member9.png';
import img10 from '../../assets/Nfts/member10.png';

const Section = styled.section`
min-height: 100vh;
width: 100%;
height: auto;
background-color: ${props => `rgba(${props.theme.bodyRgba}, 0.9)`};

position: relative;
display:flex;
flex-direction:column;
`

const Title = styled.h1`
font-size: ${(props) => props.theme.fontxxl};
text-transform: capitalize;
color: ${props => `rgba(${props.theme.textRgba}, 0.9)`};
display: flex;
justify-content: center;
align-items: center;
margin: 1rem auto;
border-bottom: 2px solid ${props => `rgba(${props.theme.textRgba}, 0.9)`};
width: fit-content;

@media (max-width: 40em) {
  font-size: ${(props) => props.theme.fontxl};
}

`

const Container = styled.div`
width: 75%;
margin: 2rem auto;

display: flex;
justify-content: space-between;
align-items: center;
flex-wrap: wrap;

@media (max-width: 64em) {
  width: 80%;
}

@media (max-width: 48em) {
  width: 90%;
  justify-content: center;
}
`

const Item = styled.div`
width: calc(20rem-4vw);
padding: 1rem 1rem;
color: ${props => `rgba(${props.theme.textRgba}, 0.9)`};
margin: 2rem 1rem;
position: relative;
z-index: 5; //places the items on top
justify-content: center;

backdrop-filter: blur(4px);

border: 2px solid ${props => `rgba(${props.theme.textRgba}, 0.9)`};
border-radius: 20px;


&:hover{
  img{
    transform: translateY(-2rem) scale(1.2);
  }
}

@media (max-width: 30em) {
  
  width: 100%;
}
`

const IconList = styled.div`
display: flex;
align-items: center;
margin: 1rem auto;



&>*{
  padding-right: 0.5rem;
  transition: all 0.2s ease;

  &:hover{
    transform: scale(1.1);
  }
}
`




const ImageContainer = styled.div`
width: 15rem;
margin: 0 auto;
background-color: black;
border: 1px solid ${props => `rgba(${props.theme.textRgba}, 0.9)`};
padding: 1rem 1rem 1rem;

border-radius: 20px;
cursor: pointer;



img{
  width: 100%;
  height: auto;
  transition: all 0.3s ease;
}

`

const Name = styled.h2`
font-size: ${props => props.theme.fontlg};
display: flex;
align-items: center;
justify-content: center;
text-transform: uppercase;
color: ${props => `rgba(${props.theme.textRgba}, 0.9)`};
font-weight: 600;
margin-top: 1rem;
`

const Position = styled.h2`
font-size: ${props => props.theme.fontmd};
display: flex;
align-items: center;
justify-content: center;
text-transform: capitalize ;
color: ${props => `rgba(${props.theme.textRgba}, 0.7)`};
font-weight: 400;
`

//const Row = styled.div`
///* background-color: lightblue; */
//white-space: nowrap;
//box-sizing: content-box;
//margin: 1rem auto;
//display: flex;
//
//`

const MemberComponent = ({img, name = " ", position = " "}) => {
  return (
    <Item>
      <ImageContainer>
        <img src={img} alt={name} />
      </ImageContainer>
      <Name>{name}</Name>
      <Position>{position}</Position>
    </Item>
  )
}


const Team = () => {
  return (
    <Section id= "team">
      
      <Title>Team</Title>
      <Container>
        <MemberComponent img={img1} name= <a href="https://twitter.com/LostRealmsXYZ" target="_blank" rel="noopener noreferrer">Gokce</a> position="Creative Direction" />
        <MemberComponent img={img2} name= <a href="https://twitter.com/LostRealmsXYZ" target="_blank" rel="noopener noreferrer">Julian</a> position="Game Design" />
        <MemberComponent img={img3} name= <a href="https://twitter.com/LostRealmsXYZ" target="_blank" rel="noopener noreferrer">Mete</a> position="Pixel Artist" />
        <MemberComponent img={img4} name= <a href="https://twitter.com/LostRealmsXYZ" target="_blank" rel="noopener noreferrer">Christophe</a> position="Blockchain Architect" />
        <MemberComponent img={img5} name= <a href="https://twitter.com/LostRealmsXYZ" target="_blank" rel="noopener noreferrer">Edgardo</a> position="Frontend Developer" />
        <MemberComponent img={img6} name= <a href="https://twitter.com/LostRealmsXYZ" target="_blank" rel="noopener noreferrer">Rafa</a> position="Backend Developer" />
        <MemberComponent img={img7} name= <a href="https://twitter.com/LostRealmsXYZ" target="_blank" rel="noopener noreferrer">Nick</a> position="Product Design" />
        <MemberComponent img={img8} name= <a href="https://twitter.com/LostRealmsXYZ" target="_blank" rel="noopener noreferrer">Irena</a> position="Pixel Artist" />
        <MemberComponent img={img9} name= <a href="https://twitter.com/LostRealmsXYZ" target="_blank" rel="noopener noreferrer">Piotr</a> position="Pixel Artist" />
        <MemberComponent img={img10} name= <a href="https://twitter.com/LostRealmsXYZ" target="_blank" rel="noopener noreferrer">Tyler</a> position="Story & Lore" />
      </Container>
      <IconList>
          <a href="https://discord.gg/lostrealms" target='_blank' rel="noopener noreferrer">
              <Partners />
            </a>
            
            
          </IconList>
    </Section>
  )
}

export default Team