import React from "react";
import styled, { ThemeProvider } from "styled-components";

import {dark, light} from '../../styles/Themes';
import gem from "../../assets/LR/gem.gif";

const Section = styled.section`
min-height: 100vh;
width: 100%;
background-color:black;


display: flex;
justify-content: center;
align-items: center;
position: relative;
`

const Container = styled.div`
width: 75%;
margin: 1rem auto;
//background-color: lightblue;

display: flex;
justify-content: center;
align-items: center;

@media (max-width: 70em) {
    width: 85%
}

@media (max-width: 64em) {
    width: 100%;
    flex-direction: column;

    &>*:last-child{
        width: 80%;
    }
}

@media (max-width: 40em) {
   
    &>*:last-child{
        width: 90%;
    }
}
`

const Box = styled.div`
width: 50%;
height: 100%;
min-height: 60vh;
margin: 1rem;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;

@media (max-width: 40em) {
    min-height: 50vh;
}
`

const Title = styled.h2`
font-size: ${(props) => props.theme.fontxxl};
text-transform: capitalize;
color: ${props => `rgba(${props.theme.textRgba}, 0.9)`};
align-self: flex-start;
width: 80%;
margin: 0 auto;

@media (max-width: 64em) {
    width: 100%;
    text-align: center;
}

@media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontxl};
}

@media (max-width: 30em) {
    font-size: ${(props) => props.theme.fontlg};
}
`

const Subtext = styled.p`
font-size: ${(props) => props.theme.fontlg};
color: ${(props) => props.theme.body};
align-self: flex-start;
width: 80%;
margin: 1rem auto;
font-weight: 400;

@media (max-width: 64em) {
    width: 100%;
    text-align: center;
    font-size: ${(props) => props.theme.fontmd};
}

@media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontmd};
}

@media (max-width: 30em) {
    font-size: ${(props) => props.theme.fontsm};
}

`


const SubtextLight = styled.p`
font-size: ${(props) => props.theme.fontmd};
color: ${props => `rgba(${props.theme.textRgba}, 0.9)`};
align-self: flex-start;
width: 80%;
margin: 1rem auto;
font-weight: 400;

@media (max-width: 64em) {
    width: 100%;
    text-align: center;
    font-size: ${(props) => props.theme.fontsm};
}

@media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontsm};
}

@media (max-width: 30em) {
    font-size: ${(props) => props.theme.fontxs};
}

`



const About2 = () => {
    return(
        <Section id= "about">
            
            <Container>
                
                <Box>
                    <SubtextLight>
                     An MMORPG Built for Web3
                    </SubtextLight>
                    <Title>
                    Defend The Realms
                    </Title>
                    <Subtext>
                    Explore the Lost Realms and do battle with its hostile inhabitants, as you seek to recapture your homeland.
                    <br/> Lost Realms kicks off with a live world map as players uncover resources, heroes, and pets. 
                    <br/> Our gameplay is built to scale with a dynamic world that is driven by player decisions, all under the watch of the almighty Oracle.

                    <br/>Each hero will be truly generative with custom art and unique battle stats on mint. Holders can collect multiple heroes with unique skills and classes, and build more powerful armies.
                    <br/> Players will also have the opportunity to train heroes, unlock levels, and acquire battle bonuses.

                    </Subtext>
                    
                    
                </Box>
                <Box> <img src={gem} alt="Logo" /> </Box>
            </Container>
        </Section>

    
      )
}

export default About2