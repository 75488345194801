import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger';
import React, { useLayoutEffect ,useRef } from 'react'
import styled from 'styled-components'
import Accordion from '../Accordion'

const Section = styled.section`
min-height: 100vh;
width: 100vw;
height: auto;
background-color: ${props => `rgba(${props.theme.bodyRgba}, 0.9)`};
position: relative;
color: ${(props) => props.theme.body};

display: flex;
justify-content: center;
align-items: center;
flex-direction:column;
`

const Title = styled.h1`
font-size: ${(props) => props.theme.fontxxl};
text-transform: capitalize;
color: ${props => `rgba(${props.theme.textRgba}, 0.9)`};

margin: 1rem auto;
border-bottom: 2px solid ${(props) => props.theme.body};
width: fit-content;

@media (max-width: 48em) {
  font-size: ${(props) => props.theme.fontxl};
}

`

const Container = styled.div`
width: 75%;
margin: 2rem auto;

display: flex;
justify-content: space-between;
align-content: center;

@media (max-width: 64em) {
  width: 80%;

}
@media (max-width: 48em) {
  width: 90%;
  flex-direction: column;

  &>*:last-child{
    &>*:first-child{

      margin-top: 0;
    }
  }

}

`

const Box = styled.div`
width: 45%;
@media (max-width: 64em) {
  width: 90%;
  align-self: center;
}
`

const Faq = () => {

  const ref = useRef(null);

  gsap.registerPlugin(ScrollTrigger);

  useLayoutEffect(() => {
    
    let element = ref.current;

    ScrollTrigger.create({
      trigger: element,
      start: 'top top',
      end: 'bottom top',
      pin: true,
      pinSpacing: false,
      scrub: true,
    })
  
    return () => {
      ScrollTrigger.killAll();
    };
  }, [])

  return (
    <Section ref = {ref} id= "faq">
      <Title>
      FAQ
      </Title>
      <Container>
        <Box>
          <Accordion title="WHAT IS THE LOST REALMS?" >
          Lost Realms is a Web3 MMORPG created by Tribe, a Web3 entertainment studio. 
          </Accordion>

          <Accordion title="WHO IS ON THE TEAM?" >
          Our team of 30+ at Tribe has worked at studios such as Lucasarts, MachineZone, NaturalMotion, Wildlife, EA, Microsoft, and Jam City. Across the team we've had roughly 20 #1 iOS and Android hits (including CSR Racing), and a few console hits (including Star Wars: The Force Unleashed). Leadership has been in the NFT space since early-mid 2021 and we have been developing in stealth for ~12 months. 
          
          </Accordion>

          <Accordion title="WHAT ARE THE DIFFERENT ROLES?" >
          @Souls of the Forgotten and @Redeemed are the introductory roles and do not differ meaningfully at this point. @Enlightened is our Allow List role. @Council of Guardians is for Tribe Diamond pass holders, the genesis collection for the studio. Other roles, such as @Torchbearer, are subsets of the AL role, and awarded through special events. Ally roles (such as @Other Allies) is through collaborator and launch partners, and are also AL'ed.
          </Accordion>
          </Box>
          <Box>
          <Accordion title="WHAT SHOULD I DO TO GET ENLIGHTENED?" >
          We hold events intermittently that can AL participants. Check 🗣whispers-hints to see what events are planned and ongoing. Infrequently, we also AL chatters in the 🍻general-tavern. We perform comprehensive checks of the identity and history for all. Please see pinned posts in the relevant channels for further guidance.
          </Accordion>
          <Accordion title="I WON AN ALLOWLIST ROLE THROUGH A GIVEAWAY IN ANOTHER COMMUNITY, WHAT SHOULD I DO?" >
          You'll automatically be given the @Enlightened role once we've finalized the list with the partnering community. Please allow up to 48 hours for this process to occur. You'll also be assigned an associated role for your community, such as @Other Allies.
          </Accordion>
          <Accordion title="WHAT ARE THE MINT DETAILS?" >
          The total supply will be 6742. Mint date and price are TBA.
          </Accordion>

        </Box>
      </Container>

    </Section>
  )
}

export default Faq