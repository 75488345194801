import React from 'react'
import styled from 'styled-components'
import GIF from '../assets/Home GIF.gif'
import LostRealms from "../assets/LR/LR black.png";



const VideoContainer = styled.div`
width: 100%;

img{
    width: 100%;
    height: auto;
    
}

@media (max-width: 64em) {

  min-width: 40vh;
  
}

`

const CoverVideo = () => {
  return (
    <VideoContainer>
        <img src= {LostRealms} type="png" alt= "Forgotten Entities" autoPlay muted loop/>
    </VideoContainer>
  )
}

export default CoverVideo