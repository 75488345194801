import React from 'react'
import styled from 'styled-components'


import Crystal from "../assets/LR/Crystal.gif";
import Logo from "../assets/LR/LR logo.png";


const Section = styled.section`

width: 100%;
height: 25rem;
position: relative;

background-image: url(${Crystal});
background-size: inherit;
background-repeat: no-repeat;
background-color: black;
display: flex;
justify-content: center;
align-items: center;


overflow: hidden;

@media (max-width: 48em) {
  height: 15rem;
  flex-direction: column;

}
`

const ImgContainer= styled.div`
width: 30%;

position: absolute;



display: flex;
justify-content: center;
align-items: center;



img{
    width: 100%;
    height: inherit;
    background-size: contain;
   
}

@media (max-width: 48em) {
    img{
    width: 10rem;
    height: auto;
}

}
`








const Banner = () => {
  return (
    <Section>
        <ImgContainer><img src={Logo} alt = "Logo" /></ImgContainer>
        
    </Section>
  )
}

export default Banner